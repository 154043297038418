import React from "react";
import {
  FooterContainer,
  FooterBox,
  Row,
  ColLeft,
  ColCenter,
  ColRight,
  Copyright,
  SocialRow,
  LogoImgContainer,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  InnstaIcon,
  StyledIconAnchor,
  StyledLink,
  StyledAnchor,
  InfoContainer,
  InfoBox,
  InfoText,
  SiteGroup,
  SiteGroupTitle,
  AddressText,
  PpTcCenter,
} from "./styles";
import FooterLogo from "./Images";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterBox>
        <Row>
          <ColLeft>
            <StyledLink to="/">
              <LogoImgContainer>
                <FooterLogo />
              </LogoImgContainer>
            </StyledLink>

            <SocialRow>
              <StyledIconAnchor
                href="https://www.facebook.com/GippslandOrthodontics"
                target="_blank"
              >
                <FacebookIcon />
              </StyledIconAnchor>
              <StyledIconAnchor
                href="https://www.linkedin.com/in/adam-wallace-714830229/"
                target="_blank"
              >
                <LinkedinIcon />
              </StyledIconAnchor>
              <StyledIconAnchor
                href="https://www.instagram.com/gippslandorthodontics"
                target="_blank"
              >
                <InnstaIcon />
              </StyledIconAnchor>
            </SocialRow>
          </ColLeft>
          <ColCenter />
          {/* <ColCenter>
            <SiteGroup>
              <SiteGroupTitle>Support</SiteGroupTitle>

              <StyledLink to="/">Home</StyledLink>

              <StyledLink to="/about">About Us</StyledLink>

              <StyledLink to="/contact">Contact Us</StyledLink>
            </SiteGroup>
          </ColCenter>
          <ColCenter>
            <SiteGroup>
              <SiteGroupTitle>Services</SiteGroupTitle>
              <StyledLink to="/services">Our Services</StyledLink>
              <StyledLink to="/new-patient">Start The Process</StyledLink>
              <StyledLink to="/referral">Referral Form</StyledLink>
            </SiteGroup>
          </ColCenter> */}

          <ColRight>
            <InfoContainer>
              <InfoBox>
                <StyledAnchor href="tel:03 8580 2489">
                  <InfoText>03 8580 2489</InfoText>
                </StyledAnchor>
              </InfoBox>
              <InfoBox>
                <StyledAnchor href="mailto:admin@gippslandorthodontic.com.au">
                  <InfoText>admin@gippslandorthodontic.com.au</InfoText>
                </StyledAnchor>
              </InfoBox>
              <InfoBox>
                <StyledAnchor
                  href="https://www.google.com/maps/place/Gippsland+Orthodontics/@-38.1613282,145.9370479,17z/data=!3m1!4b1!4m6!3m5!1s0x6b29978d1c2a05b1:0x890d78cf54718107!8m2!3d-38.1613282!4d145.9396282!16s%2Fg%2F11t3tf7xzp?entry=ttu"
                  target="_blank"
                >
                  <AddressText>
                    83 Albert Street, Warragul, VIC, Australia 3820
                  </AddressText>
                </StyledAnchor>
              </InfoBox>
            </InfoContainer>
          </ColRight>
        </Row>
        <Row>
          <PpTcCenter>
            <StyledLink to="/privacy-policy">Privacy Policy</StyledLink>
            <StyledLink to="/terms-conditions">Terms and Conditions</StyledLink>
            <StyledLink to="/service-policy">Our Service Policy</StyledLink>
          </PpTcCenter>
        </Row>
        <Copyright>
          Copyright &copy; {new Date().getFullYear()} Gippsland Orthodontics.
          All rights reserved.
        </Copyright>
      </FooterBox>
    </FooterContainer>
  );
};

export default Footer;
