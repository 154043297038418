export const menus = [
  {
    title: "Who We Are",
    id: 0,
    to: "/who-we-are",
    submenus: [
      {
        to: "/orthodontist",
        title: "Our Principal Orthodontist",
        id: "our_principal_orthodontist",
      },
      { to: "/our-team", title: "Our Team", id: "our_team" },
      { to: "/our-practice", title: "Our Practice", id: "our_practice" },
    ],
  },
  {
    title: "What We Do",
    id: 1,
    to: "/what-we-do",
    submenus: [
      {
        to: "/what-we-do/early-treatment",
        title: "Early treatment",
        id: "early_treatment",
      },
      { to: "/what-we-do/braces", title: "Braces", id: "braces" },
      {
        to: "/what-we-do/clear-braces",
        title: "Clear Braces",
        id: "clear-braces",
      },
      {
        to: "/what-we-do/clear-aligners",
        title: "Clear Aligners",
        id: "clear_aligners",
      },
      {
        to: "/what-we-do/surgical-orthodontics",
        title: "Surgical Orthodontics",
        id: "surgical_orthodontics",
      },
    ],
  },
  {
    title: "Patient Information",
    id: 2,
    to: "/patient-information",
    submenus: [
      {
        to: "/patient-information/first-visit",
        title: "Your First Orthodontic Visit",
        id: "your_first_orthodontic-visit",
      },
      {
        to: "/patient-information/patient-form",
        title: "Patient Forms",
        id: "patient_forms",
      },
      {
        to: "/patient-information/payment-information",
        title: "Payment Information",
        id: "payment_information",
      },
      {
        to: "/patient-information/emergencies",
        title: "Emergencies",
        id: "emergencies",
      },
      {
        to: "/patient-information/foods-and-habits-to-avoid",
        title: "Foods And Habits To Avoid",
        id: "foods_and_habits_to_avoid",
      },
      {
        to: "/patient-information/faq",
        title: "FAQs",
        id: "faqs",
      },
    ],
  },
  {
    title: "Referral Form",
    id: 3,
    to: "/referral",
  },
  {
    title: "Contact Us",
    id: 4,
    to: "/contact",
  },
];
