import React from "react";
import MessengerCustomerChat from "react-messenger-customer-chat";
import PropTypes from "prop-types";
import { createGlobalStyle } from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { ModalProvider, BaseModalBackground } from "styled-react-modal";
import Header from "../header";
import Footer from "../footer";
import { colors } from "../../styles/variables";

const GlobalStyle = createGlobalStyle`
    body {
        font-family: 'Red Hat Display', sans-serif;
        font-size: 16px;
        color: ${colors.dark};
        margin: 0;
        padding: 0;
    }
    
`;

const FadingBackground = styled(BaseModalBackground)`
  background-color: rgba(0, 0, 0, 0.8);
  opacity: ${({ opacity }: { opacity: number }) => opacity};
  transition: opacity ease 200ms;
  z-index: 1001;
`;

const Main = styled.main`
  background-color: ${(props) => props.color};
`;

const Layout = ({ children, bgColor }) => {
  return (
    <>
      <GlobalStyle />
      <Header />
      <ModalProvider backgroundComponent={FadingBackground}>
        <Main role="main" color={(bgColor = colors.primaryLight)}>
          {children}
        </Main>
      </ModalProvider>
      {/* <div>
        <MessengerCustomerChat pageId="107428154905173" />
      </div> */}
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  bgColor: PropTypes.string,
};

export default Layout;
