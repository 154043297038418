import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const FooterLogo = () => {
  const data = useStaticQuery(graphql`
    query {
      footerLogoImg: file(relativePath: { eq: "GOlogoWebPage-tran.png" }) {
        childImageSharp {
          fixed(width: 250) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `);
  return (
    <Img fixed={data.footerLogoImg.childImageSharp.fixed} fadeIn={false} />
  );
};

export default FooterLogo;
